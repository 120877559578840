import React, { useState } from 'react';
import Button from '../button/button';
import styles from "./style.module.scss";
import {isMobile} from 'react-device-detect';

export default function Hero(props) {
    let isMainCta;
    if (props.isMainCta) 
    {
        isMainCta = true;
    }
    return (
        <div>
            {props.onlyBreadcrumb ? null :
                <div className={props.mini ? styles.mainContainerMini : styles.mainContainer} style={{ backgroundImage: "url(site_images/" + props.image + ")" }}>
                    <div className={styles.contentContainer}>
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-12 col-lg-7"}>
                                    <div className={styles.title} style={props.margintop ? {marginTop : props.margintop, position : 'absolute', left: '50%', transform: 'translate(-50%)'}: null}>
                                        {props.title}
                                    </div>
                                    <div className={[props.noLineHeight ? styles.subtitleNoLH : styles.subtitle ]}>
                                        {props.subtitle}
                                    </div>
                                    {props.button ?
                                        <div className={styles.actionContainer} style={props.marginbottom ? {marginBottom: props.marginbottom, position: 'absolute', left: '50%', transform: 'translate(-50%)'} : null }>
                                            <Button
                                                label={props.button}
                                                variation="tertiary"
                                                labelSize = {isMainCta ? (isMobile ? 15 : 24) : 15} 
                                            />
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {props.breadcrumb ?
                <div className={styles.breadcrumbContainer} style={isMobile ? {marginRight: 20}: {marginRight: 70}}>
                    {
                        props.breadcrumb.map((el, index) => {
                            return (
                                <div className="d-inline-block">
                                    <a href={el.link}>{el.label}</a>
                                    {index < props.breadcrumb.length - 1 ? " / " : " "}
                                </div>
                            );
                        })}
                </div>
                : null
            }
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import styles from "./style.module.scss";

import Button from "../../components/button/button";
import Rating from "../../components/rating/rating";
import CarPreview from "../../components/carPreview/carPreview";
import MyCarousel from '../../components/carousel/carousel';

import {ajaxGet, ajaxPost} from "../../helper/ajax";
import { useNavigate } from "react-router-dom";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';

export default function Home() {
    const navigate = useNavigate();

    const [carList, setCarList] = useState([]);
    const [porscheList, setporscheList] = useState([]);
    const [filterList, setFilterList] = useState({});

    const [carType, setCarType] = useState("usato");
    const [carBrand, setCarBrand] = useState(null);
    const [carModel, setCarModel] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);


    useEffect(() => {
        ajaxGet("getVclFeatured.php").then((response) => {
            if (response.code == "100") {
                setCarList(response.message)
            }
        });

        let formdata = new FormData();
        formdata.append("brand", "PORSCHE");
        ajaxPost("getVclList.php", formdata).then((response) => {
            if (response.code == "100") {
                setporscheList(response.message.car)
            }
        });

        ajaxGet("getFilters.php").then((response) => {
            if (response.code == "100") {
                setFilterList(response.message);
            }
        });

    }, [])

    return (
        <Layout>
            <Hero
                image={"bg_hero_home.png"}
                title={"Benvenuti in\nGruppo Milanoauto"}
                margintop = {isMobile ? -128 : null}
                subtitle={"Specializzati  Audi, Volkswagen, Porsche"}
                isMainCta={true}
                marginbottom = {isMobile ? -128 : null}
            />

            <section className='sections_home' style={{marginBottom: 0}}>
                <div className="container">
                    <div className={styles.searchContainer}>
                        <div className={styles.searchButtonContainer}>
                            <input type="radio" className="btn-check" value="usato" name="carType" id="option4" defaultChecked onChange={(val) => setCarType(val.target.value)}/>
                            <label className={styles.searchButton} htmlFor="option4">Usato</label>

                            <input type="radio" className="btn-check" value="semestrale" name="carType" id="option3" onChange={(val) => setCarType(val.target.value)} />
                            <label className={styles.searchButton} htmlFor="option3">Semestrale</label>

                            <input type="radio" className="btn-check" value="km 0" name="carType" id="option2" onChange={(val) => setCarType(val.target.value)}/>
                            <label className={styles.searchButton} htmlFor="option2">KM 0</label>

                            <input type="radio" className="btn-check" value="nuovo" name="carType" id="option1" onChange={(val)=>setCarType(val.target.value)}/>
                            <label className={styles.searchButton} htmlFor="option1">Nuovo</label>

                            <input type="radio" className="btn-check" value="nuovo arrivo" name="carType" id="option5" onChange={(val) => setCarType(val.target.value)}/>
                            <label className={styles.searchButton} htmlFor="option5">Nuovi arrivi</label>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="row">
                                <div className="col">
                                    <select className="w-100" style={{padding: 12}} onChange={(val) => { setCarBrand(val.target.value); setCarModel(""); }} required >
                                        <option value={""} selected>Marca</option>
                                        {filterList.brand ? Object.keys(filterList.brand).map((el) => {
                                            return (<option value={el}>{el}</option>)
                                        })
                                            : null
                                        }
                                    </select>
                                </div>
                                <div className="col">
                                    <select className="w-100" style={{padding: 12}} value={carModel} onChange={(val) => { setCarModel(val.target.value); }} required>
                                        <option value={""} selected>Modello</option>
                                        {filterList.brand && carBrand ?
                                            filterList.brand[carBrand].map((el) => {
                                                return (<option value={el}>{el}</option>)
                                            })
                                            : null
                                        }
                                    </select>
                                </div>
                                <div className="col">
                                    <select className="w-100" style={{padding: 12}} onChange={(val) => setMaxPrice(val.target.value)} required >
                                        <option value={""} selected>Prezzo fino a</option>
                                        <option value={500}>500 €</option>
                                        <option value={1000}>1.000 €</option>
                                        <option value={1500}>1.500 €</option>
                                        <option value={2000}>2.000 €</option>
                                        <option value={2500}>2.500 €</option>
                                        <option value={3000}>3.000 €</option>
                                        <option value={4000}>4.000 €</option>
                                        <option value={5000}>5.000 €</option>
                                        <option value={6000}>6.000 €</option>
                                        <option value={7000}>7.000 €</option>
                                        <option value={8000}>8.000 €</option>
                                        <option value={9000}>9.000 €</option>
                                        <option value={10000}>10.000 €</option>
                                        <option value={12500}>12.500 €</option>
                                        <option value={15000}>15.000 €</option>
                                        <option value={17500}>17.500 €</option>
                                        <option value={20000}>20.000 €</option>
                                        <option value={25000}>20.000 €</option>
                                        <option value={30000}>30.000 €</option>
                                        <option value={40000}>40.000 €</option>
                                        <option value={50000}>50.000 €</option>
                                        <option value={75000}>75.000 €</option>
                                        <option value={100000}>100.000 €</option>
                                    </select>
                                </div>
                                <div className="col-auto">
                                    <Button
                                        style={{paddingTop: 12, paddingBottom: 12}}
                                        label={"Cerca"}
                                        variation={"tertiary"}
                                        onClick={() => navigate('veicoli', {
                                            state: {
                                                type: carType,
                                                brand: carBrand,
                                                model: carModel,
                                                maxPrice: maxPrice
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ maxWidth: 1545, margin: 'auto' }}>
                <div className={"container py-3"}>
                    <h2 className="text-center" style={isMobile ? {marginTop:26, marginBottom: 35} : null}>Scopri le nostre ultime novità</h2>
                    <MyCarousel
                        desktop={3}
                        showPartial={true}
                        showArrow={true}
                        showArrowTablet={false}
                        showArrowMobile={false}
                        showDots={false}
                        showDotsTablet={true}
                        showDotsMobile={true}
                        containerClass="car-slider"
                        >
                        {carList.map((el, i) => {
                            return <CarPreview data={el} key={"car-preview-" + i} slideN={i}/>
                        })}
                    </MyCarousel>
                    <Button
                        label={"Scopri tutte le offerte"}
                        variation={isMobile ? "tertiaryMobile" : "tertiary"}
                        onClick={() => window.location.href = "veicoli"}
                        style={isMobile ? {margin:"auto", marginTop:48, fontSize: 13} : {margin:"auto", marginTop:48}}
                        />
                </div>
            </section>

            <section style={{maxWidth:1320 , margin: 'auto', marginTop: -8, marginBottom: 16}}>
                <div className="container-fluid py-5">
                            <h2 className="text-center">Perchè scegliere Gruppo Milanoauto</h2>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3">
                            <div className={styles.whyRow} style={{marginLeft: -45}}>
                                <img src="site_images/svg/Shield.svg" width={44} height={44} className={styles.whyIcon} />
                                <h4>Oltre 30 anni di esperienza</h4>
                            </div>
                            <div className={styles.whyRow} style={{marginLeft: -45}}>
                                <img src="site_images/svg/Marchi.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{ marginRight: -25 }}>Specializzati in Audi e Volkswagen </h4>
                            </div>
                            <div className={styles.whyRow} style={{marginLeft: -45}}>
                                <img src="site_images/svg/Gamma.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{ marginRight: -40 }}>Vasta gamma di vetture e allestimenti </h4>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-center">
                            <img src="site_images/auto-business-1.png" className={styles.whyImage} />
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className={styles.whyRow}>
                                <img src="site_images/svg/Finanziamento.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{ marginRight: -45 }}>Finanziamenti e leasing personalizzati</h4>
                            </div>
                            <div className={styles.whyRow}>
                                <img src="site_images/svg/Value.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{ marginRight: -70 }}>Miglior prezzo grazie all’Importazione Diretta </h4>
                            </div>
                            <div className={styles.whyRow}>
                                <img src="site_images/svg/Optionals.svg" width={48} height={48} className={styles.whyIcon} />
                                <h4 style={{ marginRight: -20 }}>Offerta multimarca in pronta consegna</h4>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className={styles.porscheSection} 
                //style={isMobile ? { margin:"auto", marginTop:32, fontSize: 13} : {margin:"auto", marginTop:32}}
                style={{ backgroundImage: "url(site_images/bg_home_porsche.png)" }}>
                <div className={"container py-5"}>
                    
                    <h2 style={{marginBottom:0}}>Entra nel mondo Porsche</h2>
                    <p style={{ marginBottom: 64 }}>del Gruppo Milanoauto</p>
                    
                    <div className={"my-3"} />

                    <MyCarousel
                        desktop={3}
                        tablet={2}
                        mobile={1}
                        showPartial={true}
                    >
                        {porscheList.map((el, i) => {
                            return <CarPreview data={el} key={"car-preview-porsche-" + i} />
                        })}
                    </MyCarousel>
                    <div className={"my-3 mt-4"} />
                    <Button
                        label={"Scopri tutte le Porsche"}
                        variation={isMobile ? "tertiaryMobile" : "tertiary"}
                        onClick={() => window.location.href = "porsche"}
                        style={isMobile ? {margin:"auto", fontSize: 13} : {margin:"auto"}}
                    />
                </div>
            </section>

            <section className={[styles.serviceSection, { marginTop: '-64px' } ]}>
                <div className={isMobile ? "container" : "container py-1"}>
                    <div className="row">
                        <div className={"col-12 col-md-4"} style={{ maxWidth: 1545, marginTop: 'auto', marginLeft: '-90px', marginRight: '90px' }}>
                            <img src="site_images/home_servizi.jpg" /*width={478} height={465}*/ className="img-fluid d-none d-md-block" style={{ borderRadius: '40px', marginTop: 10, width: '100%', heigth: '100%', marginLeft: 40 }} />
                        </div>
                        <div className="col-12 col-md-8" style={isMobile ? null : {marginLeft: -20}}>
                            <h2 className="text-center text-md-start" style={isMobile ? {marginBottom: 26, marginTop: -29} : {marginBottom: 25}}>Servizi</h2>
                            <MyCarousel
                                desktop={2}
                                tablet={1}
                                arrowPosition={"bottomRight"}
                                showArrow={true}
                                showDots={false}
                                showDotsTablet={true}
                                showDotsMobile={true}
                            >
                                <div className={styles.serviceCard}>
                                    <div className={styles.serviceCardTop} style={{maxWidth: '360px'}}>
                                        <img src="site_images/svg/Noleggio_lungo_termine.svg" width={65} height={65} />
                                        <div>
                                            <h3>Servizio di</h3>
                                            <h4 className="primary-color servizi_home" style={{fontSize:24}}>Noleggio a lungo termine</h4>
                                        </div>
                                    </div>
                                    <p style={{maxWidth: '360px'}}>Il Noleggio a Lungo Termine cerca di rispondere in particolare alle esigenze delle aziende e dei liberi professionisti.<br/>
                                        Negli ultimi anni però anche i privati si sono affidati sempre di più al Noleggio a Lungo Termine per i vantaggi che offre.
                                        </p>
                                    <Button label="Scopri di più" variation="tertiary" onClick={() => window.location.href='servizi#service_2'}/>
                                </div>


                                <div className={styles.serviceCard}>
                                    <div className={styles.serviceCardTop} style={{maxWidth: '360px'}}>
                                        <img src="site_images/svg/Noleggio_camper.svg" width={51} height={62} />
                                        <div>
                                            <h3>Servizi</h3>
                                            <h4 className="primary-color servizi_home" style={{fontSize:24}}>Noleggio camper</h4>
                                        </div>
                                    </div>
                                    <p style={{maxWidth: '360px'}}>Gruppo Milanoauto è partner di FreewayCamper. Puoi contare sul supporto di un nostro consulente presso la nostra sede di Cologno Monzese per godere di tutti i vantaggi del noleggio di FreewayCamper:</p>
                                    <Button label="Scopri di più" variation="tertiary" onClick={() => window.location.href = 'servizi#service_3'} />
                                </div>

                                <div className={styles.serviceCard}>
                                    <div className={styles.serviceCardTop} style={{maxWidth: '360px'}}>
                                        <img src="site_images/svg/Finanziamento.svg" width={48} height={48} />
                                        <div>
                                            <h3>Servizi di</h3>
                                            <h4 className="primary-color servizi_home" style={{fontSize:24}}>Finanziamento</h4>
                                        </div>
                                    </div>
                                    <p style={{maxWidth: '360px'}}>I nostri consulenti sono sempre pronti a valutare ogni aspetto per formulare insieme la soluzione migliore alle tue esigenze 
                                        Con noi sarai in grado di personalizzare la rata includendo comodamente anche coperture assicurative, pacchetti di estensione garanzia o manutenzione.
                                        </p>
                                    <Button label="Scopri di più" variation="tertiary" onClick={() => window.location.href = 'servizi#service_1'} />
                                </div>
                            </MyCarousel>
                        </div>
                    </div>
                </div>
            </section>

            <section className={[styles.sectionBrands, 'sections_home']} style={isMobile ? { marginBottom: 52 } : { marginBottom: 100, marginTop: -10 }}>
                <div className={isMobile ? "container text-center" : "container py-4 text-center"} style={isMobile ? {marginBottom:32} : null}>
                    <h2 style={isMobile ? {marginBottom: 28, marginTop: -24} : null}>I nostri brand</h2>
                    <div className={styles.brandsContainer} style={isMobile ? { marginBottom: 32 } : { marginBottom: 40 }}>
                        <img src="site_images/brands/wolksvagen.png" />
                        <img src="site_images/brands/audi.png" />
                        <img src="site_images/brands/mercedes.png" />
                        <img src="site_images/brands/porsche.png" className="d-none d-md-block"/>
                        <img src="site_images/brands/seat.png" className="d-none d-md-block" />
                        <img src="site_images/brands/skoda.png" className="d-none d-md-block"/>
                        <img src="site_images/brands/cupra.png" className="d-none d-lg-block"/>
                        <img src="site_images/brands/ford.png" className="d-none d-lg-block" />
                    </div>
                    <Button label="Tutti i marchi" variation="tertiary" style={isMobile ?{ display: 'block', margin: 'auto', marginTop: 32} :{ display: 'block', margin: '0 auto'}} onClick={() => window.location.href = 'marchi'}  />
                </div>
            </section>

            <section className={styles.sectionAssistance} style={{marginTop:-40,paddingBottom:100, backgroundColor: '#D8DEE9'}}>
                <div className={isMobile ? "container" : "container py-0"} style={isMobile ? {marginTop: 78, marginBottom: -10} : {marginTop: 78, marginBottom: 52}}>
                    <h2 style={isMobile ? { marginBottom: 14, marginTop:-35 } : null}>Assistenza</h2>
                    <MyCarousel desktop={1} tablet={1} arrowPosition={"bottomCenter"} showArrow={true}
                        showDots={false}
                        showDotsTablet={true}
                        showDotsMobile={true}
                    >
                        <div className={styles.assistanceCard}>
                            <div className="row m-0">
                                {/* Immagine a sinistra su schermi più ampi */}
                                <div className="col-md-7 p-0">
                                    <img src="site_images/assistenza.jpg" className={styles.assistanceCardImage} style={{ borderRadius: 40, marginTop: 10, width: '100%'}} />
                                </div>

                                {/* Colonna di testo a destra su schermi più ampi */}
                                <div className="col-md-5 p-5" style={isMobile ? {marginTop:-17} : null}>
                                    <div className={styles.serviceCard}>
                                        <div className={styles.serviceCardTop}>
                                            <img src="site_images/svg/Officina.svg" width={64} height={64} />
                                            <div className='text-start'>
                                                <h3>Gruppo Milanoauto</h3>
                                                <h4 className="primary-color">Service</h4>
                                            </div>
                                        </div>
                                        <p>La nostra officina di Cormano si prende cura della tua auto in ogni aspetto. 
                                            Il team è a disposizione per garantire la sicurezza dei tuoi viaggi attraverso tagliandi e varie manutenzioni legate a freni, sospensioni, olio, luci e molto altro.</p>
                                        <Button label="Scopri di più" variation="tertiary" onClick={() => window.location.href = 'assistenza#assistenza_1'} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.assistanceCard}>
                            <div className="row m-0">
                                {/* Immagine a sinistra su schermi più ampi */}
                                <div className="col-md-7 p-0">
                                    <img src="site_images/assistenza-1.png" className={styles.assistanceCardImage} style={{ borderRadius: '40px', marginTop: 10, width: '100%'}} />
                                </div>

                                {/* Colonna di testo a destra su schermi più ampi */}
                                <div className="col-md-5 p-5">
                                    <div className={styles.serviceCard}>
                                        <div className={styles.serviceCardTop}>
                                            <img src="site_images/svg/Officina.svg" width={64} height={64} />
                                            <div className='text-start'>
                                                <h3>Gruppo Milanoauto</h3>
                                                <h4 className="primary-color">Carrozzeria</h4>
                                            </div>
                                        </div>
                                        <p>I servizi di carrozzeria curano l’estetica della tua auto ed operano in perfetta sinergia con l’officina. 
                                            I nostri tecnici specializzati sono pronti ad occuparsi di danni ingenti ma anche di piccole riparazioni o ritocchi. 
                                            Forniamo supporto completo in seguito ad un sinistro, con la gestione delle pratiche assicurative, cessione del credito risarcitorio, soccorso stradale e vettura sostitutiva. 
                                            Ci occupiamo direttamente della messa in dima del telaio e della verniciatura del veicolo.</p>
                                        <Button label="Scopri di più" variation="tertiary" onClick={() => window.location.href = 'assistenza#assistenza_2'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MyCarousel>
                </div>
            </section>

            <section className={styles.sectionAssistance} style={isMobile ? {marginBottom: 46} :  null}>
                <div className={isMobile ? "container text-center" : "container py-2 text-center"} style={isMobile ? {marginTop: -27} :  {marginBottom: '-40px'}}>
                    <h2>Dicono di noi</h2>

                    <div className={"row"} style={isMobile ? {marginTop: '-44px'} : null}>
                        <MyCarousel
                            desktop={3}
                            tablet={1}
                            mobile={1}
                            showArrow={false}
                            showDots={false}
                            showDotsTablet={true}
                            showDotsMobile={true}
                        >
                            <Rating
                                title="TITOLO"
                                description="Desideravo una Q3 Sportback in pronta consegna, dopo diverse ricerche mi sono recato qui. Sono rimasto sorpreso dall'ampia scelta di auto disponibili. Sono passato da non riuscire a trovarla o con tempi di consegna biblici alla possibilità di scegliere tra più colori e averla subito. Molto soddisfatto e i venditori molto disponibili. Grazie!!!"
                                vote={5}
                                name="Pietro Salza"
                                fontsize={isMobile ? 15 : null}
                                marginHorizontal={65}
                            />
                            <Rating
                                title="TITOLO"
                                description="Arrivata una gran macchina nei tempi concordati. Riccardo veramente gentile e professionale così come tutto il team!"
                                vote={5}
                                name="Luca Marelli"
                                fontsize={isMobile ? 15 : null}
                                marginHorizontal={60}
                            />
                            <Rating
                                title="TITOLO"
                                description="Competenza e cortesia per quanto riguarda l'assistenza vendita e post-vendita. Belle occasioni anche sull'usato. Personale capace di guidarti nella scelta della vettura."
                                vote={5}
                                name="AnnaMaria Beda"
                                fontsize={isMobile ? 15 : null}
                                marginHorizontal={60}
                            />
                            <Rating
                                title="TITOLO"
                                description="Competenza e cortesia sono il loro punto di forza, sia durante la vendita che per quanto riguarda l'assistenza post vendita."
                                vote={5}
                                name="Gabrieli Papetti"
                                fontsize={isMobile ? 15 : null}
                                marginHorizontal={60}
                            />
                            <Rating
                                title="TITOLO"
                                description="Ottima presentazione del personale, cortese e gentile. Abbiamo parlato con Marco, gentile e cortese, molto preparato si è dimostrato capace nel gestire le richieste fatte. Anche il collega impegnato con altri clienti mi è sembrato molto preparato nei prodotti e nelle richieste."
                                vote={5}
                                name="Fabrizio Margiuseppe"
                                fontsize={isMobile ? 15 : null}
                                marginHorizontal={60}
                            />
                            <Rating
                                title="TITOLO"
                                description="L'auto era esattamente come da foto e rispettava la descrizione. Sono stato contattato in breve tempo dal venditore che con molto garbo ha risposto ad ogni mia domanda. Il passo successivo è stato prenotare un appuntamento presso il concessionario. Posso ritenermi soddisfatto dell'acquisto e dell'ottimo rapporto qualità prezzo. La struttura è seria e affidabile e le persone che lavoro all'interno sono molto preparate."
                                vote={5}
                                name="Simone Panfilo"
                                fontsize={isMobile ? 15 : null}
                                marginHorizontal={60}
                            />
                            <Rating
                                title="TITOLO"
                                description="Personale gentilissimo. In poco tempo mi hanno fatto sentire molto bene e hanno trovato la giusta quadra per il finanziamento. Veramente molto soddisfatto. Consiglio in pieno. Grazie"
                                vote={5}
                                name="Marco Cavenago"
                                fontsize={isMobile ? 15 : null}
                                marginHorizontal={60}
                            />

                        </MyCarousel>
                    </div>
                </div>
            </section>

        </Layout>
    );
}


import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Hero from '../../components/hero/hero';

import Layout from '../../components/layout/layout';
import Button from '../../components/button/button';

import parse from 'html-react-parser';
import Content from './readMore';

import styles from "./style.module.scss";
import { ajaxGet, ajaxPost } from "../../helper/ajax";
import Lightbox from "yet-another-react-lightbox";
import { IMG_URL } from "../../constant"
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Counter from "yet-another-react-lightbox/plugins/counter";

import MyCarousel from '../../components/carousel/carousel';
import CarPreview from "../../components/carPreview/carPreview";

import { toFormat, getCookieForBookmark, setCookieForBookmark } from "../../helper/functions";
import { BASENAME, COOKIE_BOOKMARKS, COOKIE_DURATION_DAYS } from "../../constant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {isMobile} from 'react-device-detect';

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";


export default function Vehicle() {
    const ref = useRef(null);

    const handleClick = () => {

        const id = 'contact-form';
        const yOffset = -150;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });

        //ref.current ?.scrollIntoView({ behavior: 'smooth' });
    };


    const id = useParams().id;

    const [car, setCar] = useState(null);

    const [openLightbox, setOpenLightbox] = React.useState(false);

    const [bookmarked, setBookmarked] = useState(false);

    const [sectionsEquip, setSectionEquip] = useState([true,true,true,true,true]);
    //const [sectionsButtonEquip, setSectionsButtonEquip] = useState([true,true,true,true,true]);

    const [carListFeatured, setCarListFeatured] = useState([]);

    function getBookmarkedValue(carId) {
        // verificare se il cookie esiste
        var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS+"_"+carId);
        // ritorna true o false in base al valore (null → false)
        if (cookieValue === true) {
            //console.log(`Try to check for → ${carId} as Bookmarked`);
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (!car) {
            let formData = new FormData();
            formData.append("id", id);
            //console.log(`Try to call page with id = ${id}`);
            ajaxPost("getVcl.php", formData).then((response) => {
                if (response.code == "100") {
                    setBookmarked(getBookmarkedValue(id));
                    //setBookmarked(isBookmarked());
                    setCar(response.message)
                }
            });
        }

        ajaxGet("getVclFeatured.php").then((response) => {
            if (response.code == "100") {
                setCarListFeatured(response.message)
            }
        });
    }, [])

    function dotThePrice(carPrice) {
        var priceToArray = [];
        const splitIndex = carPrice.length - 3;
        priceToArray = carPrice.split("");
        priceToArray.splice(splitIndex, 0, ".");
        carPrice = priceToArray.join('');
        return carPrice;
    }

    function addBookmark() {
        if (car) {
            // verificare se il cookie esiste
            var cookieValue = getCookieForBookmark(COOKIE_BOOKMARKS+"_"+car.vcl.VclId);
            // ritorna true o false in base al valore (null → false)
            var cookieFlag = cookieValue ? false : true
            // cambio il valore dell'hook "bookmarked"
            setBookmarked(cookieFlag)
            //console.log(`WRITE ${car.vcl.VclId} → TO ${cookieFlag}`);
            // salva true o false nel cookie (es. nome → milAutoCookieBook14980 )
            setCookieForBookmark(COOKIE_BOOKMARKS+"_"+car.vcl.VclId, cookieFlag, COOKIE_DURATION_DAYS);
        }
    }

    if (!car) { return null }
    const gallery = car.vcl.img.map(el => ({ src: IMG_URL + el.FullPath }));

    const renderAttribute = (title, value, icon) => {
        if (!value) { value = "" }
        return (
            <div className="col-12 col-lg-4 col-xl-3 my-3" key={title + value}>
                <div className={styles.attribute}>
                    <div className={styles.attibuteIcon}>
                        <img src={"../site_images/svg/" + icon} width={40} height={40} />
                    </div>
                    <div className={styles.attibuteText}>
                        <div className={styles.attributeTitle}>{title}</div>
                        <div className={styles.attributeValue}>{value.toLowerCase()}</div>
                    </div>
                </div>
            </div>
        )
    }

    const renderOptional = (title, value, index) => {
        if (!value) { value = "" }

        return (
            <div className="col-md-3 col-sm-6 mb-4 d-flex" key={title + value + index}>
                <div className={styles.optional}>
                    <div className={styles.optionalIcon}>
                        <img src={"../site_images/svg/Check.svg"} width={32} height={32} />
                    </div>
                    <div className={styles.optionalText}>
                        <div className={styles.optionalTitle}>{title}</div>
                        <div className={styles.optionalValue}>{value.toLowerCase()}</div>
                    </div>
                </div>
            </div>
        )
    }

    const renderOptionalList = (eqp) => {
        let order = ["Comfort", "Esterni", "Interni", "Servizi", "Sicurezza","Altro"]
        let arr = {};

        eqp.map((el) => {
            if (!arr[el.Categoria]) {
                arr[el.Categoria] = { title: el.Categoria, items: [] }
            }
            arr[el.Categoria].items.push(el.Descrizione);
        })

        let renderArr = [];
        for (let i = 0; i < order.length; i++) {
            if (arr[order[i]]) {
                renderArr.push(renderOptionalRow(arr[order[i]].title, arr[order[i]].items, i))
            }
        }
        return renderArr
    }
    
    const renderOptionalRow = (title, items, optSection) => {
        return (
            <div className="row mt-5" style={{borderBottom:"1px solid black"}}>
                <div className="col-12 col-md-4"><h2>{title}</h2></div>
                <div className="col-12 col-md-8">
                    <div className="row">
                        {items.map((el, index) => {
                            return (renderOptionalItem(el, checkItemLength(items), sectionsEquip[optSection], index))
                        })}
                    </div>
                </div>
                {
                    checkItemLength(items) ?
                    // sectionsEquip[optSection] ? 
                    <Button 
                        label={sectionsEquip[optSection] ? `Altri optional +` : `Meno optional -`}
                        variation="primary"
                        // onClick={() => setSectionEquip[optSection] = !sectionsEquip[optSection]} 
                        onClick={() => handleChangeButtonOptional(optSection)}
                        style={{ margin: 'auto',marginBottom: 30, width:200 }}
                    />
                    : null
                }
            </div>
        )
    }

    const handleChangeButtonOptional = (optSectionPos) => {
        // Creare una copia dell'array
        const newArray = sectionsEquip.slice();
    
        // Modificare il primo elemento della copia in base al parametro
        newArray[optSectionPos] = !newArray[optSectionPos];
    
        // Aggiornare lo stato con la nuova copia
        setSectionEquip(newArray);
    };

    function checkIfOverSixthElement(indexNumber) {
        if (indexNumber > 5) return true
        return false
    }

    const renderOptionalItem = (item, limitRenderElements, buttonMoreOptions, indexOptional) => {
        if (limitRenderElements && (checkIfOverSixthElement(indexOptional)) && buttonMoreOptions) {
            return null
        } else {
            return (
                // <div>
                //     Ciao {indexOptional} - {checkIfOverSixthElement(indexOptional).toString()} → {limitRenderElements.toString()}
                // </div>
                <div className="col-12 col-sm-6 mb-5">
                        <div className={styles.optional}>
                            <div className={styles.optionalIcon}>
                                <img src={"../site_images/svg/Check.svg"} width={24} height={24} />
                            </div>
                            <div className={styles.optionalText}>
                                <div className={styles.optionalValue}>{item}</div>
                            </div>
                        </div>
                </div>
            )
        }
    }

    // function checkMaxEquip(totalEquipNo) {
    //     if (totalEquipNo > (6 * (sectionsEquip ? 10 : 1))) {
    //         return true;
    //     }
    //     return false;
    // }

    function checkItemLength(optionalList) {
        const opt =  optionalList.toString().split(',');
        if (opt.length > 6)
            return true
        return false
    }

    function infoRequest() {
        handleClick()
        const element = document.getElementById('hidden_data');
        setNativeValue(element, "Veicolo: " + car.vcl.VclId+"<br>");
    }

    function setNativeValue(element, value) {
        let lastValue = element.value;
        element.value = value;
        let event = new Event("input", { target: element, bubbles: true });
        // React 15
        event.simulated = true;
        // React 16
        let tracker = element._valueTracker;
        if (tracker) {
            tracker.setValue(lastValue);
        }
        element.dispatchEvent(event);
    }


    return (
        <Layout>
            <Lightbox
                open={openLightbox}
                close={() => setOpenLightbox(false)}
                slides={gallery}
                plugins={[Zoom, Thumbnails, Counter]}

            />
            <Hero
                onlyBreadcrumb={true}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "I nostri Veicoli", link: BASENAME + "veicoli" },
                    { label: car.vcl.Marca + " " + car.vcl.Modello, link: null }
                ]}

            />

            <section >
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h2 className="mb-0">{car.vcl.Marca}</h2>
                            <p><span style={{ fontWeight: 'bold' }}>{car.vcl.Modello}</span> &nbsp; → {
                                car.vcl.DescrizioneSynth ?
                                    <span>{car.vcl.DescrizioneSynth.toLowerCase()}</span>
                                    : null
                            }</p>
                        </div>

                        <div className={styles.shareContainer}>
                            <FontAwesomeIcon icon={[bookmarked ? "fas" : "far", "heart"]} onClick={() => addBookmark()} />
                            <img src="../site_images/icon/icon_share.png" onClick={() => 
                                window.open("https://api.whatsapp.com/send?text=https://www.gruppomilanoauto.it/veicolo/"+id)    
                            } />

                        </div>

                    </div>

                    <div className={styles.imageContainer}>
                        <table className="w-100">
                            <tbody>
                                <tr>
                                    <td rowSpan={2} className="w-50">
                                        <img src={IMG_URL + car.vcl.img[0].FullPath} className={styles.vehicleImage} />
                                    </td>
                                    <td>
                                        <img src={IMG_URL + car.vcl.img[1].FullPath} className={styles.vehicleImage} />
                                    </td>
                                    <td>
                                        <img src={IMG_URL + car.vcl.img[2].FullPath} className={styles.vehicleImage} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={IMG_URL + car.vcl.img[3].FullPath} className={styles.vehicleImage} />
                                    </td>
                                    <td>
                                        <img src={IMG_URL + car.vcl.img[4].FullPath} className={styles.vehicleImage} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={styles.buttonContainerImage}>
                            <Button label={"Guarda altre foto"} variation={"5"} onClick={() => setOpenLightbox(true)} />
                        </div>
                    </div>

                    <div className={styles.imageContainerMobile}>
                        <MyCarousel
                            mobile={1}
                            tablet={1}
                            desktop={1}
                            showDefaultArrow={true}
                        >
                            {car.vcl.img.map((el) => {
                                return (
                                    <img src={IMG_URL + el.FullPath} />
                                )
                            })}
                        </MyCarousel>
                    </div>
                </div>
            </section>



            <section style={{ marginTop: isMobile?0:'-30px', marginBottom: '-10px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12  col-lg-4 mb-5">
                            <div className={styles.priceCard}>
                                <div className={styles.prezzo}><small> Tasse incl.</small></div>
                                
                                <div className={styles.priceText}>{dotThePrice(car.vcl.PrezzoFinale)} €</div>
                                <p className={styles.prezzo}>
                                    <bottom>
                                    Escluso: messa su strada e immatricolazione
                                    </bottom>
                                </p>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                <div className={styles.priceCardButtonContainer}>
                                    <Button label={isMobile ? "Richiedi info" : "Richiedi informazioni"} variation={"tertiary"} onClick={()=>infoRequest()} />
                                    <Button icon={"phone"} variation={"secondaryNoB"} onClick={() => window.open("tel:022540348")} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-8 mb-5">
                            <div className={styles.attributeContainer}>
                                <div className={"row"}>
                                    {renderAttribute("Immatricolazione", car.vcl.Immatricolazione, "Immatricolazione.svg")}
                                    {renderAttribute("Alimentazione", car.vcl.Alimentazione, "Alimentazione.svg")}
                                    {renderAttribute("Cilindrata", toFormat(car.vcl.Cilindrata), "Cilindrata_potenza.svg")}
                                    {renderAttribute("Potenza", car.vcl.Kw + " kW (" + car.vcl.Cv + " CV)", "Cilindrata_potenza.svg")}
                                    {renderAttribute("Cambio", car.vcl.Cambio, "Cambio.svg")}
                                    {renderAttribute("Colore", car.vcl.CarrColore, "Colore.svg")}
                                    {renderAttribute("Chilometraggio", toFormat(car.vcl.Percorrenza), "Chilometraggio.svg")}
                                    {renderAttribute("Tipologia", car.vcl.ClasseComm, "Tipologia.svg")}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section>
                <div className="container">
                    <div className="col-12">
                        <h4 className="mb-4">Equipaggiamenti Optional</h4>
                        {renderOptionalList(car.vcl.eqp)}
                    </div>
                </div>
            </section> 

            <section>
                <div className="container">
                    <h4>Descrizione del veicolo</h4>
                    <p>
                        <div className="my-3" >
                            <div>
                                <div className={styles.attibuteText}>
                                    {
                                        car.vcl.InfoAggiuntive ?
                                            <div numberoflines={3} className={styles.attributeValue}>
                                                <Content descriptionText={parse(car.vcl.InfoAggiuntive)} />
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </p>
                </div>
            </section>



            {/*
            <section>
                <div className="container">
                    <div className="col-12">
                        <h4 className="mb-4">Equipaggiamenti Optional</h4>
                        <div className="row">
                            {
                                car.vcl.eqp.map((el, index) => {
                                    // check index number to set how many options must to be rendered
                                    if (index < sectionsEquip * 12) {
                                        return renderOptional(el.Descrizione, el.Categoria, index)
                                    }
                                })
                            }
                            <div className={styles.buttonContainerImage}>
                                {
                                    checkMaxEquip(car.vcl.eqp.length) ?
                                        // (car.vcl.eqp.length > (12*sectionsEquip))
                                        <Button label={`Altro`} variation={"5"} onClick={() => setSectionEquip(sectionsEquip + 1)} />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */}
            <section style={{ marginTop: '-80px', marginBottom: '-16px' }}>
                <div className="container my-5">
                    <div className={styles.contactCard}>
                        <h4>Richiedi informazioni per questo veicolo</h4>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque semper ac orci in vulputate.</p> */}
                        <div className={styles.contactCardButtonContainer}>
                            <Button label={"Richiedi un preventivo"} variation="tertiary" onClick={handleClick} />
                            <Button label={"Richiedi informazioni"} variation="tertiary" onClick={handleClick} />
                            {/* <Button label={"Prenota un test drive"} variation="tertiary" /> */}
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ maxWidth: 1545, margin: 'auto', marginTop: -33 }}>
                <h2 className="text-center mt-5 mb-4">Potresti anche essere interessato a questi veicoli</h2>
                <MyCarousel
                    showArrow={true}
                    containerClass="car-slider"
                    showPartial={true}
                >
                    {carListFeatured.map((el, i) => {
                        return <CarPreview data={el} key={"car-preview-" + i} />
                    })}
                </MyCarousel>
            </section>
            {/* div usato per effettuare lo scroll su form al premere dei pulsanti nel riquadro "Richiedi Info" */}
            <div ref={ref} />
        </Layout>
    );
}


import React from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import ServiceCTA from '../../components/serviceCTA/serviceCTA';
import {useLocation} from "react-router-dom";

//import styles from "./style.module.scss";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';


export default function Services() {

        const scrolledRef = React.useRef(false);
        const { hash } = useLocation();
        const hashRef = React.useRef(hash);

    React.useEffect(() => {

        const onPageLoad = () => {
            if (hash) {
                // We want to reset if the hash has changed
                if (hashRef.current !== hash) {
                    hashRef.current = hash;
                    scrolledRef.current = false;
                }

                // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
                if (!scrolledRef.current) {
                    const id = hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                        scrolledRef.current = true;
                    }
                }
            }
        };


        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }

        });



    return (
        <Layout>
            <Hero
                image={"bg_hero_services.png"}
                title={"Servizi"}
                subtitle={"Scopri i servizi offerti dal Gruppo Milanoauto"}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "Servizi", link: null }
                ]}
            />

            <ServiceCTA
                sectionStyle={isMobile ? { maxWidth: 1320, margin: '0 auto', marginTop:16 } : { maxWidth: 1320, margin: '0 auto' }}
                id="service_1"
                title="Finanziamenti e pacchetti assicurativi"
                icon="icon_finanziaria.png"
                description={<div>I nostri consulenti sono sempre pronti a valutare ogni aspetto per formulare insieme la soluzione migliore alle tue esigenze<br />Con noi sarai in grado di personalizzare la rata includendo comodamente anche coperture assicurative, pacchetti di estensione garanzia o manutenzione. <br /> <br />Non rinunciare a soluzioni come <b>Anticipo Zero o Maxirata</b>, ogni elemento è flessibile e modulabile al fine di pianificare al meglio le tue spese.</div>}
                image={"service-1.jpg"}
                mobileImage={"service-1-mobile.png"}
                checkmarks={[
                    { title: "Leasing personalizzati", description: "" },
                    { title: "Anticipo Zero", description: "" },
                    { title: "Assicurazioni su misura", description: "" }]}
                    subtext={<div><b>Scrivici</b> per valutare l'importo che ti occorre finanziare e ti ricontatteremo <b>entro 48 ore</b>.</div>}
                    buttonText={"02 2540348"}
                    buttonText2={"Richiedi informazioni"}
                    buttonAction={() => window.open('tel:022540348')}
                    buttonAction2={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1?text=' + encodeURIComponent("prova messaggio"))}                    
            />
            <ServiceCTA
                sectionStyle={{ maxWidth: 1320, margin: '0 auto' }}
                id="service_2"
                title="Noleggio a Lungo Termine"
                icon="icon_noleggio.png"
                description={<div>Il Noleggio a Lungo Termine cerca di rispondere in particolare alle esigenze delle aziende e dei liberi professionisti.<br />
                    Negli ultimi anni però anche i privati si sono affidati sempre di più al Noleggio a Lungo Termine per i vantaggi che offre.<br />
                    È una soluzione di mobilità che mira a risparmiare denaro ma soprattutto ad eliminare il tempo dedicato alla gestione dell’auto o della flotta aziendale.<br />
                </div>}
                image={"service-2.jpg"}
                mobileImage={"service-2-mobile.png"}
                checkmarks={[
                    { title: "Contratto su misura", description: "La percorrenza e la durata contrattuale del tuo Noleggio a Lungo Termine, è modulabile in base alle tue necessità. Con il tuo consulente dedicato di Gruppo Milanoauto puoi anche valutare un Anticipo ZERO e la permuta della tua auto." },
                    { title: "Previsione dei costi", description: "Ottieni un costo preciso del tuo parco auto che non può essere influenzato dal valore futuro del mezzo o da episodi imprevisti." },
                    { title: "Finanziamento dei costi", description: "Il canone di noleggio comprende una totalità di servizi o di oneri tributari che in questa maniera vengono dilazionati." },
                    { title: "Gestione esternalizzata", description: "Il personale dell’azienda non deve occuparsi della gestione della flotta aziendale, questo comporta una riduzione dei costi amministrativi. Tutte le manutenzioni, le scadenze e la gestione di eventuali imprevisti, sono affidati a terzi." }]}
                    imageSubtext={<p>Il Noleggio a Lungo Termine comporta un’unica rata fissa mensile, comprensiva di una copertura assicurativa completa, della manutenzione ordinaria e straordinaria dell’auto.
                                In caso di sinistro non devi pensare a nulla e puoi contare sulla detrazione dell’IVA e
                                molti altri vantaggi.</p>}
                buttonText={"02 2540348"}
                buttonText2={"Richiedi informazioni"}
                buttonAction={() => window.open('tel:022540348')}
                buttonAction2={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1?text=' + encodeURIComponent("prova messaggio"))}
                right={true}
            />
            <ServiceCTA
                id="service_3"
                title="Noleggio Camper"
                icon="icon_noleggio_camper.png"
                description={<div>Gruppo Milanoauto è partner di FreewayCamper.<br /><br />
                    Puoi contare sul supporto di un nostro consulente presso la nostra sede di Cologno Monzese per godere di tutti i vantaggi del noleggio di FreewayCamper:<br /><br />
                    Viaggia con FreewayCamper per avere la massima libertà e flessibilità! Oltre al facile processo di prenotazione con la totale trasparenza dei prezzi, non hai limiti di chilometraggio e l'attrezzatura da campeggio base è gratuita. Potrai così partire subito per la tua vacanza in campeggio. Ti offriremo un comodo appartamento su ruote, per ogni tua particolare esigenza.<br />
                </div>}
                image={"service-3.jpeg"}
                checkmarks={[
                    { title: "Prezzo equo e garantito", description: "" },
                    { title: "Noleggio a breve e lungo termine", description: "" },
                    { title: "Cambio prenotazione flessibile", description: "" },
                    { title: "Vasta scelta di campeggi", description: "" }]}
                subtext={<div><b>Conosci già la soluzione Bulli + Van?</b><br />
                    Compatto e funzionale con due zone notte, una cucina, un accogliente salotto e molto spazio per i bagagli. Grazie al tetto a soffietto avrai molta libertà di movimento anche stando in piedi.<br /><br />
                    <b>Prendi un appuntamento per conoscere dal vivo uno dei nostri VW Bulli Camper</b>
                </div>}
                buttonText={"02 2540348"}
                buttonAction={() => window.open('tel:022540348')}

                sectionStyle={{ marginBottom:0,paddingBottom:0, maxWidth: 1320, margin: '0 auto' }}
            />

            <ServiceCTA
                sectionStyle={{ paddingTop: 0, maxWidth: 1320, margin: '0 auto', marginBottom: -25 }}
                description={<div><b>{("Massima libertà e flessibilità").toUpperCase()}</b></div>}
                checkmarks={[
                    { title: "Chilometraggio illimitato", description: "" },
                    { title: "Riprenotazione gratuita", description: "" },
                    { title: "Attrezzatura di base inclusa", description: "" },
                    { title: "I cani sono i benvenuti", description: "" },
                    { title: "Nessun limite di autisti", description: "" }]}
                buttonText={"02 2540348"}
                buttonText2={"Richiedi informazioni"}
                buttonAction={() => window.open('tel:022540348')}
                buttonAction2={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1?text=' + encodeURIComponent("prova messaggio"))}

            />

            <ServiceCTA
                sectionStyle={{ maxWidth: 1320, margin: '0 auto' }}
                id="service_5"
                checkmarks={[]}
                subtext={<div>
                    <b>Tutti i chilometri sono inclusi?</b><br />
                    Non vogliamo che ti perda le destinazioni dei tuoi sogni. Ecco perché non abbiamo limiti di chilometraggio. Viaggia dove vuoi grazie al chilometraggio illimitato! (Le eccezioni si applicano solo per il noleggio in lungo termine)<br /><br />

                    <b>Riprenotazione gratuita</b><br />
                    Se devi cancellare il tuo viaggio, non c'è problema. Se la cancellazione avviene entro 48 ore prima dell'inizio del viaggio, riceverai un buono per riprenotare gratuitamente in un periodo successivo nel 2023. Chiedi al nostro consulente le informazioni dettagliate sulla politica di cancellazione<br /><br />

                    <b>Attrezzatura di base inclusa</b><br />
                    Sono finiti i giorni in cui devi preoccuparti di quali attrezzature e stoviglie portare con te. Con ogni nostro camper riceverai gratuitamente l'attrezzatura di base per il tuo viaggio. Il pacchetto include: il pacchetto cucina, due sgabelli e un tavolo, il pacchetto protezione Bronze, cunei drive-up, cavi elettrici e una consulenza approfondita in anticipo. Inoltre, è possibile prenotare attrezzature extra come il portabiciclette, toilette mobile e altri servizi.<br /><br />

                    <b>I tuoi animali da compagnia sono i benvenuti</b><br />
                    I cani sono i benvenuti su tutti i nostri camper. Con una tariffa di servizio di 85 € puoi portare il tuo cane con te durante il viaggio. Nel pacchetto sono inclusi: una cuccia, una cintura di sicurezza, una protezione per il sedile e un ancoraggio per il pavimento. Il tuo cane avrà anche a disposizione una ciotola per il cibo.<br /><br />

                    <b>Nessun limite di autisti</b><br />
                    Spesso è necessario più di un autista. Per questo motivo non abbiamo un limite di numero di autisti. L'unico requisito è che il conducente sia in possesso di una patente di guida valida da almeno un anno. È sufficiente portare le carte d'identità e le patenti di tutti i conducenti al momento del ritiro.<br /><br />

                    <b>Contattaci per maggiori informazioni sul Noleggio Camper</b><br />
                </div>}
                image={"service-6.png"}
                buttonText={"02 2540348"}
                buttonText2={"Richiedi informazioni"}
                buttonAction={() => window.open('tel:022540348')}
                buttonAction2={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1?text=' + encodeURIComponent("prova messaggio"))}
                right={true}
            />

        </Layout>
    );
}


import React from 'react';
import styles from "./style.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {isMobile} from 'react-device-detect';

export default function Rating(props) {

    const renderStar = (n) => {
        if (n < props.vote) {
            return <FontAwesomeIcon icon={"star"} color={"#43A4ED"} />
        }
        return <FontAwesomeIcon icon={"star"} color={"#D9D9D9"} />
    }

    return (
        <div className={styles.ratingCard} style={props.marginHorizontal ? { marginLeft: props.marginHorizontal, marginRight: props.marginHorizontal } : null}>
            <h4 style={{fontSize: props.fontsize}}>{props.name}</h4>
            <img src="site_images/virgolette.png" />
            <p className={styles.ratingtext}>{props.description}</p>

            <div className={styles.ratingStarContainer}>
                {renderStar(0)}
                {renderStar(1)}
                {renderStar(2)}
                {renderStar(3)}
                {renderStar(4)}
            </div>
            {/*
            <h3 className={styles.name}>{props.name}</h3>
            */}
        </div>

    );
}


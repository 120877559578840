import React from 'react';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import AssistanceCTA from '../../components/assistanceCTA/assistanceCTA';
import {isMobile} from 'react-device-detect';
import { useLocation } from "react-router-dom";

import styles from "./style.module.scss";
import { BASENAME } from "../../constant";


export default function Assistance() {
    const scrolledRef = React.useRef(false);
    const { hash } = useLocation();
    const hashRef = React.useRef(hash);

    React.useEffect(() => {

        const onPageLoad = () => {
            if (hash) {
                // We want to reset if the hash has changed
                if (hashRef.current !== hash) {
                    hashRef.current = hash;
                    scrolledRef.current = false;
                }

                // only attempt to scroll if we haven't yet (this could have just reset above if hash changed)
                if (!scrolledRef.current) {
                    const id = hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                        scrolledRef.current = true;
                    }
                }
            }
        };


        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }

    });



    return (
        <Layout>
            <Hero
                image={"bg_hero_assistance.png"}
                title={"Assistenza"}
                subtitle={isMobile ? null : "Possiamo prenderci cura della tua auto grazie alla nostra officina e carrozzeria."}
                noLineHeight={true}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "Assistenza", link: null }
                ]}
            />

            <AssistanceCTA
                id="assistenza_1"
                title="Service"
                icon="icon_officina.png"
                sectionBottom={false}
                description={<div>
                    La nostra officina di Cormano si prende cura della tua auto in ogni aspetto. Il team è a disposizione per garantire la sicurezza dei tuoi viaggi attraverso tagliandi e varie manutenzioni legate a freni, sospensioni, olio, luci e molto altro. Inoltre disponiamo di una grande disponibilità di ricambi per tutti i tipi di auto.
                    </div>}
                image={"assistenza-1.png"}
                checkmarks={[
                    { title: "Tagliando", description: "" },
                    { title: "Revisione", description: "" },
                    { title: "Cambio gomme", description: "" }
                ]}
                subtext={<div><b>Prenota ora</b> un appuntamento</div>}
                buttonText={"Email"}
                buttonText2={"Scrivici su Whatsapp"}
                buttonAction={() => window.open('mailto:service@gruppomilanoauto.it')}
                //buttonAction2={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1?text=' + encodeURIComponent("prova messaggio"))}
                buttonAction2={() => window.open('https://wa.me/+393280303945')}
            />

            <AssistanceCTA
                id="assistenza_2"
                title="Carrozzeria"
                icon="icon_carrozzeria.png"
                sectionBottom={true}
                description={
                    <div>
                        I servizi di carrozzeria curano l’estetica della tua auto ed operano in perfetta sinergia con l’officina. I nostri tecnici specializzati sono pronti ad occuparsi di danni ingenti ma anche di piccole riparazioni o ritocchi. Forniamo supporto completo in seguito ad un sinistro, con la gestione delle pratiche assicurative, cessione del credito risarcitorio, soccorso stradale e vettura sostitutiva. Ci occupiamo direttamente della messa in dima del telaio e della verniciatura del veicolo.
                    </div>
                }
                image={"assistenza-2.jpg"}
                checkmarks={[
                    { title: "Riparazioni carrozzeria", description: "" },
                    { title: "Verniciatura a forno", description: "" },
                    { title: "Lucidatura e rigenerazione fari", description: "" },
                    { title: "Levabolli", description: "" }
                ]}
                subtext={<div><b>Prenota ora</b> un appuntamento</div>}
                buttonText={"Email"}
                //buttonText2={"Prenota ora un appuntamento"}
                buttonAction={() => window.open('mailto:carrozzeria@gruppomilanoauto.it')}
                //buttonAction2={() => window.open('mailto:carrozzeria@gruppomilanoauto.it')}
                right={true}
            />
        </Layout>
    );
}

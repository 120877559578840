import React from 'react';
import styles from "./style.module.scss";
import Button from "../../components/button/button";

export default function AssistanceCTA(props) {

    return (
        <><div className="anchor" id={props.id}></div>

            <section className={props.sectionBottom ? `${styles.section} ${styles.sectionBottom}` : styles.section}>
                <div className="container">
                    <div className={props.right ? "row flex-row-reverse" : "row"}>
                        <div className="d-none d-md-block col-md-5">
                            <img src={"site_images/" + props.image} className={styles.image} style={{ borderRadius: 40 }} />
                        </div>
                        <div className="d-block d-md-none col-12 text-center">
                            {props.image ?
                                <img src={"site_images/" + props.image} className={"w-100 mb-3"} style={{ borderRadius: 40 }} />
                                : null
                            }
                        </div>

                        <div className="col-12 col-md-7 pe-md-5 text-center text-md-start">
                            <div className={styles.titleRow}>
                                <img src={"site_images/icon/" + props.icon}></img>
                                <h2>{props.title}</h2>
                            </div>
                            <p>{props.description}</p>
                            <div className={"my-4 mt-5"}>
                                {props.checkmarks.map((el, index) => {
                                    return (
                                        <div key={index} className={styles.checkmarksContainer}>
                                            <img src="site_images/icon/icon_check.png" />
                                            <div>
                                                <h2>{el.title}</h2>
                                                {el.description != "" ? (<p>{el.description}</p>) : null}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <p className="mt-5">{props.subtext}</p>
                            <div className={""}>
                                {props.imageSubtext}
                                <div className="d-flex justify-content-center justify-content-md-start">

                                    <Button
                                        label={props.buttonText}
                                        variation={"4"}
                                        iconRight={["fas", "envelope"]}
                                        onClick={props.buttonAction}
                                    />


                                    {props.buttonText2 ?
                                        <>
                                            <div className="d-none d-md-block">
                                                <Button
                                                    label={props.buttonText2}
                                                    variation={"tertiary"}
                                                    icon={["fab", "whatsapp"]}
                                                    onClick={props.buttonAction2}
                                                    isWhatsapp={true}
                                                />
                                            </div>
                                            <div className="d-block d-md-none">
                                                <Button
                                                    variation={"tertiary"}
                                                    icon={["fab", "whatsapp"]}
                                                    onClick={props.buttonAction2}
                                                    isWhatsapp={true}
                                                />
                                            </div>
                                        </>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}


import React, { useState, useEffect } from 'react';
import styles from "./style.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerLink from "./headerLink";
import Button from "../button/button";
import { BASENAME } from "../../constant";
import { SMTP } from "../../constant"
import {isMobile} from 'react-device-detect';

// import headerLogo from "../../img/logo_milanoauto.png"
// import headerLogo from "../../site_images/svg/Logo_milanoauto.svg"

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";

import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import arrow from "../../img/arrow-down.png";

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <img src={arrow} className={styles.chevron} />
            </>
        }
        className={styles.item}
        buttonProps={{
            className: ({ isEnter }) =>
                `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`
        }}
        contentProps={{ className: styles.itemContent }}
        panelProps={{ className: styles.itemPanel }}
    />
);

function userAction() {
    window.location.href = BASENAME + "preferiti";
}

export default function Header() {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className={styles.headerContainer}>
            <div className={styles.headerTopRow} >
                <div className={styles.headerTopRowLeft}>
                    {headerLink.top.map((link, index) => {
                        return (
                            <a key={"top-link-" + index} href={BASENAME + link.link} className={styles.headerTopLink}>{link.label}</a>
                        )
                    })}
                </div>
                <div className={styles.headerTopRowRight}>
                    <Button label="Preferiti" variation="primary" icon={["far", "heart"]} style={{ height: 40 }} iconSize={20} onClick={() => userAction()} />
                </div>
            </div>

            <div className={styles.divider} />
            
            
            <div className={`${styles.headerBottomRow} ${isSticky ? styles.stickyHeader : styles.idleHeader}`}>
                <div className={styles.headerBottomRowLeft}>
                    <div>
                        <img src="../site_images/svg/Logo_milanoauto.svg" width={300} height={42} style={{ maxWidth: '100%', height: 'auto', marginLeft: -35 }} onClick={() => window.location.href = BASENAME} />
                        {/* <img className={styles.headerLogo} src="../../svg/Logo_milanoauto.svg" alt="logo" onClick={() => window.location.href = BASENAME} /> */}
                    </div>
                </div>

                <div className={styles.headerBottomRowRight}>
                    <div className={styles.headerBottomLinkContainer}>
                        {/*
                         {headerLink.bottom.map((link, index) => {
                            return (
                                <a key={"bottom-link-" + index} href={BASENAME +link.link} className={styles.headerBottomLink}>{link.label}</a>
                            )
                        })}
                        */}

                        {/*navbar desktop*/}
                        <Navbar expand="lg" className="bg-body-tertiary">
                            <Container>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        {headerLink.bottom.map((link, index) => {
                                            if (link.submenu) {
                                                return (
                                                    <NavDropdown className={styles.headerBottomLink} key={"bottom-link-" + index} title={link.label} id={link.label + "-dropdown"}>
                                                        {link.submenu.map((sublink, index) => {
                                                            return (
                                                                <NavDropdown.Item key={"submenu-" + index + sublink.label} href={BASENAME + sublink.link}>{sublink.label}</NavDropdown.Item>
                                                            )
                                                        })}
                                                    </NavDropdown>

                                                );
                                            }

                                            return (
                                                <Nav.Link key={"bottom-link-" + index} href={BASENAME + link.link} className={styles.headerBottomLink}>{link.label}</Nav.Link>
                                            )
                                        })}

                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        {/*fine navbar desktop*/}
                    </div>
                    <div className={styles.headerTopMenuMobile} style={isMobile ? {marginRight:-30} : null}>
                        <Button variation="primary" icon={["far", "heart"]} iconSize={18} style={{marginRight: 18, height: 30 }} onClick={() => userAction()} />
                        <FontAwesomeIcon icon="bars" className={styles.headerOpenMenuButton} style={{ marginRight: -5}} onClick={() => setMenuOpen(!menuOpen)} />
                    </div>
                </div>
            </div>

            <div className={styles.headerMenuMobileContainer} style={{ left: (menuOpen) ? "0%" : "100%", right: (menuOpen) ? "0%" : "-500%" }}>
                <div className={styles.headerMenuMobile}>

                    <Accordion transition transitionTimeout={250}>
                        {headerLink.bottom.map((link, index) => {
                            if (link.submenu) {
                                return (
                                <AccordionItem key={"accordion-" + index} header={link.label} className="testdssdasdjksdla">
                                        {link.submenu.map((sublink, index) => {
                                            return (
                                                <a key={"submenu-" + index + sublink.label} href={BASENAME + sublink.link} onClick={() => setMenuOpen(!menuOpen)} className={styles.headerBottomLinkSub}>{sublink.label}</a>
                                            )
                                        })}
                                </AccordionItem>

                                );
                            }

                            return (
                                <a key={"submenu-" + index + link.label} href={BASENAME + link.link} className={styles.headerBottomLink}>{link.label}</a>
                            )
                        })}
                    </Accordion>

                    <div className={styles.divider} />

                    {headerLink.top.map((link, index) => {
                        return (
                            <a key={"top-link-mobile" + index} href={BASENAME + link.link} className={styles.headerTopLink}>{link.label}</a>
                        )
                    })}

                    <div className={styles.divider} />

                    <div className={styles.infoTitle}>
                        Gruppo Milanoauto s.r.l.
                    </div>
                    <div className={styles.infoText} style={{ marginTop: 15 }}>
                        <FontAwesomeIcon icon="phone" />
                        {headerLink.phone}
                    </div>
                    <div className={styles.infoText}>
                        <FontAwesomeIcon icon={["far", "envelope"]} />
                        {headerLink.mail}
                    </div>

                    <div className={styles.addressTitle}>
                        Sede di Cologno Monzese
                    </div>
                    <div className={styles.addressValue}>
                        Via per Imbersago 19<br/> 20093 Cologno Monzese (MI)
                    </div>

                    <div className={styles.addressTitle}>
                        Sede di Cormano
                    </div>
                    <div className={styles.addressValue}>
                        Via Pietro Nenni 8<br/>20032 Cormano (MI)
                    </div>

                    <div className={styles.socialContainer} >
                        {headerLink.socials.map((social, index) => {
                            return (
                                <Button key={"social-header-" + index} icon={social.icon} onClick={() => window.open(social.link, '_blank')} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}


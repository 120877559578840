import React from 'react';
import styles from "./style.module.scss";
import Button from "../../components/button/button";

export default function ServiceCTA(props) {

    return (
        <><div className="anchor" id={props.id}></div>
        <section className={styles.section} style={props.sectionStyle }>
            {/* style={{maxWidth: '1320px'}} */}
            <div className="container-fluid p-0">
                <div className={props.right ? "row flex-row-reverse" : "row"}>
                    <div className="d-none d-md-block col-md-5">
                        {props.image ?
                            <img src={"site_images/" + props.image} className={styles.image} 
                            // style={{ objectPosition: props.right ? "right" : "left" }} 
                            style={ props.right ? {marginLeft:-30} : {marginLeft:30} } 
                            />
                            : null
                        }
                    </div>
                    <div className="d-block d-md-none col-12 text-center">
                        {props.mobileImage ?
                            <img src={"site_images/" + props.mobileImage} className={styles.mobileImage}/>
                            : null
                        }
                    </div>

                    <div className="col-12 col-md-7 p-5">
                        {props.title ?
                            <div className={styles.titleRow}>
                                <img src={"site_images/icon/" + props.icon}></img>
                                <h2>{props.title}</h2>
                            </div>
                            : null
                        }
                        <p>{props.description}</p>
                        <div className={"my-4"}>
                            {props.checkmarks.map((el, index) => {
                                return (
                                    <div key={index} className={styles.checkmarksContainer}>
                                        <img src="site_images/icon/icon_check.png" />
                                        <div>
                                            <h2>{el.title}</h2>
                                            {el.description != "" ? (<p>{el.description}</p>) : null}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <p>{props.subtext}</p>
                        <div className={""}>
                            {props.imageSubtext}
                            <div className="d-flex justify-content-center justify-content-md-start">
                                <Button
                                    label={props.buttonText}
                                    variation={"4"}
                                    iconRight={["fas", "phone"]}
                                    onClick={props.buttonAction}
                                />

                                {props.buttonText2 ?
                                    <>
                                        <div className="d-none d-md-block">
                                            <Button
                                                label={props.buttonText2}
                                                variation={"tertiary"}
                                                icon={["fab", "whatsapp"]}
                                                onClick={props.buttonAction2}
                                                isWhatsapp={true}
                                            />
                                        </div>
                                        <div className="d-block d-md-none">
                                            <Button
                                                variation={"tertiary"}
                                                icon={["fab", "whatsapp"]}
                                                onClick={props.buttonAction2}
                                                isWhatsapp={true}
                                            />
                                        </div>
                                    </>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}


import React, {useState, useRef, useEffect} from 'react';
import emailjs from '@emailjs/browser';
import Iubenda from 'react-iubenda-policy'

import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
import Button from "../../components/button/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import markerLogo from '../../img/icon-sede.png'
import iconLocation from '../../img/icon_location.png'
import headerLink from "../../components/header/headerLink";
import { BASENAME } from "../../constant";
import {isMobile} from 'react-device-detect';

import { SMTP } from "../../constant"
import { ajaxGet, ajaxPost } from "../../helper/ajax";

import styles from "./style.module.scss";

export default function Contacts() {
    const myPolicy = 29122716

    // Call when page is rendered, it set windows size fore the used device
    useEffect(() => {
        // Function to update windowWidth when the window is resized
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup: remove event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const form = useRef();

    const [sending, setSending] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        if (sending) { return }
        console.log("invio")

        setSending(true);


        let formdata = new FormData();
        formdata.append("name", user_name);
        formdata.append("surname", user_surname);
        formdata.append("phone", user_telephone);
        formdata.append("email", user_email);
        formdata.append("subject", user_reqType);
        formdata.append("message", user_message);
        formdata.append("hidden_data", "");
        ajaxPost("sendMail.php", formdata).then((response) => {
            setSending(false);

            if (response.code == "100") {
                setSuccessMessage(true)
                setErrorMessage(false)

                setName("");
                setSurname("");
                setTelephone("");
                setEmail("");
                setMessage("");
                setReqType("");
            } else {
                setErrorMessage(true)
                setSuccessMessage(false)
            }
        });



        /*
        emailjs
        .sendForm('service_qv028gz', 'template_u42dv5g', "#myform", {
            publicKey: 'xa-Lt3NEEoYbCFzZt',
        })
        .then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
        );*/
    };
    // funzione per modificare il valore dei checkbox
    const handleChangeButtonOptional = (optCheckboxPos) => {
        // Creare una copia dell'array
        const newArray = mandatoryTerms.slice();
    
        // Modificare il primo elemento della copia in base al parametro
        newArray[optCheckboxPos] = !newArray[optCheckboxPos];
    
        // Aggiornare lo stato con la nuova copia
        setMandatoryTerms(newArray);
    };

    const [user_name, setName] = useState("");
    const [user_surname, setSurname] = useState("");
    const [user_telephone, setTelephone] = useState("");
    const [user_email, setEmail] = useState("");
    const [user_reqType, setReqType] = useState("");
    const [user_message, setMessage] = useState("");
    // hook per la gestione dei 3 checkbox
    const [mandatoryTerms, setMandatoryTerms] = useState([false,false,false]);
    // hook per la gestione del messaggio di errore
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);

    // per il controllo della larghezza dello schermo
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const marker1 = {
        lat: 45.54124660267275,
        lng: 9.288067900000001
    }

    const marker2 = {
        lat: 45.545721264819726,
        lng: 9.164600770312765
    }

    function checkEmail()    {
        console.log("checkEmail Called on 'contact.js'");
    }

    function checkIpad(screenWidth) {
        if (screenWidth < 1200)
            return true
        return false
    }

    // calcola la 
    function returnPercentage(x)
    {
        var part_1 = Math.sqrt(0.5 * x);
        var part_2 = 0.08 * x + (x/4*0.08);       
        var result =  part_1 - part_2
        console.log(` % of gap → ${result}`)
        return result
    }

    return (
        <Layout
            removeContactForm={true}
        >
            <Hero
                image={"bg_hero_contacts.png"}
                title={"Contatti"}
                subtitle={""}
                mini={true}
                breadcrumb={[
                    { label: "Home", link: BASENAME },
                    { label: "Contatti", link: null }
                ]}
            />

            <section styles={styles.container}>
                <div className="container" style={isMobile ? {marginTop:-10} : checkIpad(windowWidth) ? { maxWidth: 1200 } : null}>
                    <div className="row" style={{maxWidth: '1320px'}}>
                        <div className="col-12 col-lg-6 mt-4">
                            <h2 className="mb-3">Contatto immediato</h2>
                            <div className="row align-items-center">
				            <div className={isMobile ? "col-12 col-sm-6 d-none d-lg-block ms-0 mt-0": checkIpad(windowWidth) ? "col-12 col-sm-6 d-none d-lg-block ms-0 mt-0" :"col-12 col-lg-6"} style={isMobile ? null : {marginLeft: returnPercentage(windowWidth)}}>
                            {/* <div className="col-12 col-lg-6" style={{marginLeft: returnPercentage(windowWidth)}}> */}
                            {/* <div className={"col-12 col-sm-6 d-none d-lg-block ms-0 mt-0"}> */}
					            <img src="site_images/contacts-1.png" className="w-100 ms-n3" alt="Contact Image" />
                            </div>
                                <div className="col-5">
                                    <div className={styles.contactsContainer}>
                                    <img src="../site_images/svg/Phone_blu_scuro.svg" width={26} height={26} fill="white" style={{ textAlign: 'right', marginRight: 10 }} />
                                        {/* <FontAwesomeIcon icon={["fas", "phone"]} className={styles.contactsContainerIcon} /> */}
                                        <div onClick={() => window.open('tel:+39022540348')} style={{ cursor: "pointer" }}>
                                            <p className={styles.contactsLabel}>Telefono</p>
                                            <p className={styles.contactsValue}>02 2540348</p>
                                        </div>
                                    </div>
                                    <div className={styles.contactsContainer}>
                                        <FontAwesomeIcon icon={["far", "envelope"]} className={styles.contactsContainerIcon} />
                                        <div onClick={() => window.open('mailto:'+SMTP)} style={{cursor: "pointer"}}>
                                            <p className={styles.contactsLabel}>E-mail</p>
                                            <p className={styles.contactsValue}>info@gruppomilanoauto.it</p>
                                        </div>
                                    </div>
                                    <div className={styles.contactsContainer}>
                                        <FontAwesomeIcon icon={["fab", "whatsapp"]} className={styles.contactsContainerIcon} />
                                        <div onClick={() => window.open('https://wa.me/message/6SDAOF2KJBZNE1')} style={{ cursor: "pointer" }}>
                                            <p className={styles.contactsLabel}>Whatsapp</p>
                                            <p className={styles.contactsValue}>02 2534990</p>
                                        </div>
                                    </div>

                                    <div className="cta-menu mt-4" style={{marginBottom:10}}>Seguici su:</div>
                                    <div className="d-flex">
                                        {headerLink.socials.map((social, index) => {
                                            return (
                                                <FontAwesomeIcon key={"social-contact-" + index} icon={social.icon} style={isMobile ? {fontSize:25, marginRight:40} : {fontSize:25, marginRight:30}} className={`${styles.socialIcon} ${styles.enlargedIcon}`} onClick={() => window.open(social.link, '_blank')} />
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 mt-4">
                            <div className={styles.contactFormContainer}>
                                <h2 className="mb-3">Scrivici</h2>
                                <p>Compila il form sottostante per essere ricontattato da un nostro operatore</p>
                                <form ref={form} onSubmit={sendEmail} id='myform'>
                                    <div className={"row"}>
                                        <div className={"col-12 col-md-6"}>
                                            <input placeholder="Inserisci il tuo nome" value={user_name} name="user_name" type='text' required="required" onChange={txt => setName(txt.target.value)}   style={{outline: 'none'}}/>
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <input placeholder="Inserisci il tuo cognome" value={user_surname} name="user_surname" required="required" type='text' onChange={txt => setSurname(txt.target.value)} style={{outline: 'none'}}/>
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <input placeholder="Numero di telefono" value={user_telephone} name="user_telephone" required="required" type='tel' onChange={txt => setTelephone(txt.target.value)}  style={{outline: 'none'}}/>
                                        </div>
                                        <div className={"col-12 col-md-6"}>
                                            <input placeholder="La tua e-mail" value={user_email} name="user_email" type='email' required="required" onChange={txt => setEmail(txt.target.value)}   style={{outline: 'none'}}/>
                                        </div>
                                        <div className={"col-12"}>
                                            <select required value={user_reqType} name="user_reqType" onChange={txt => { setReqType(txt.target.value) }}>
                                                <option value="" >Tipologia di richiesta</option>
                                                <option >Acquisto</option>
                                                <option>Noleggio</option>
                                                <option>Service</option>
                                                <option>Carrozzeria</option>
                                                <option>Altro</option>
                                            </select>
                                        </div>
                                        <div className={"col-12"}>
                                            <textarea placeholder="Come possiamo aiutarti?" rows="5" type='text' onChange={txt => setMessage(txt.target.value)}  style={{outline: 'none'}}></textarea>
                                        </div>
                                        <div className={"col-12 mb-2 d-flex"} style={isMobile ? { marginTop: -10 } : null}>
                                            <input required="required" type="checkbox" id="contact-form-terms" value="1" onClick={handleChangeButtonOptional} />
                                            <label htmlFor="contact-form-terms">
                                                Acconsento al trattamento dei miei dati e dichiaro di aver preso visione della <a href="/privacy-policy">Privacy Policy</a>
                                            </label>
                                        </div>
                                        {
                                            errorMessage ? (
                                                <label style={{ color: 'red', fontWeight: 500 }}>Errore durante l'invio</label>
                                            ) : null
                                        }
                                        {
                                            successMessage ? (
                                                <label style={{ color: 'green', fontWeight: 500 }}>Email inviata con successo</label>
                                            ) : null
                                        }


                                        {
                                            isMobile ?
                                            <Button label="Invia richiesta" variation="tertiary" type="submit" style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                                            :
                                            <Button label="Invia richiesta" variation="tertiary" iconRight="arrow-right" type="submit" />
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={isMobile ? {marginTop: -90} : {marginTop: -63}}>
                <div className="container my-5">
                    <h2 style={{marginBottom:30}}>Dove siamo</h2>
                    <LoadScript
                        googleMapsApiKey="AIzaSyBSoOXo3sA84BRTi66KM-Q2SODZS0WvGyA"
                    >
                        <GoogleMap
                            mapContainerStyle={{
                                width: '100%',
                                height: '100%',
                                minHeight: '500px'
                            }}
                            center={{
                                lat: 45.47,
                                lng: 9.15
                            }}
                            zoom={11}
                        >
                            <MarkerF
                                key={"marker-1-contact-form"}
                                position={marker1}
                                icon={{
                                    url: markerLogo,
                                    scale: 7,
                                }}
                            />
                            <MarkerF
                                key={"marker-2-contact-form"}
                                position={marker2}
                                icon={{
                                    url: markerLogo,
                                    scale: 7,
                                }}
                            />
                        </GoogleMap>
                    </LoadScript>
                </div>
            </section>

            <section style={isMobile ? {marginTop:-158} : {marginTop:-110}}>
                <div className="container my-5">
                    <h2>Le nostre sedi</h2>
                    <div className="row" style={isMobile ? {marginTop:-92} : {marginTop:-100}}>
                        <div className="col-12 col-md-6" style={{marginTop:40, marginBottom: -26}}>
                            <div className="row mt-4">
                                <div className="col-12 col-sm-5">
                                    <img src="site_images/contacts-2.png" className="w-100" style={{borderRadius:40}} />
                                </div>
                                <div className="col-12 col-sm-7" style={{marginTop:16}}>
                                    {isMobile ? 
                                    (
                                        <p className="mb-0">Sede di Cologno Monzese</p>
                                    ) : (
                                        <>
                                        <p className="mb-0">Sede di</p>
                                        <p className="mb-0">Cologno Monzese</p>
                                        </>
                                    )}
                                    <h4 className="mb-3">Gam Gruppo Milanoauto</h4>
                                    <p className="mb-0"><b>Orari di apertura</b></p>
                                    <p>Lu – Ve: 09:00 – 12:00 | 14:30 – 19:00<br />Sa: 09:00 – 12:00 | 15:00 – 18:00</p>
                                    <div className={styles.contactsContainer2}>
                                        <img src={iconLocation} className={styles.contactsContainerIcon2} />
                                        <div onClick={() => window.open('https://goo.gl/maps/DLiuYGD75hQ53ym89')} style={{ cursor: "pointer" }}>
                                            <p className={styles.contactsValue2}>Via per Imbersago 19 20093 Cologno Monzese (MI)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6" style={{marginTop:40, marginBottom: -26}}>
                            <div className="row mt-4">
                                <div className="col-12 col-sm-5">
                                    <img src="site_images/contacts-3.png" className="w-100" style={{borderRadius:40}} />
                                </div>
                                <div className="col-12 col-sm-7" style={{marginTop:16}}>
                                    <p className="mb-0">Sede di Cormano</p>
                                    <h4 className="mb-3">Gam Gruppo Milanoauto</h4>
                                    <p className="mb-0"><b>Orari di apertura</b></p>

                                    <p>Lu – Ve: 09:00 – 12:00 | 14:30 – 19:00<br />Sa: 09:00 – 12:00 | 15:00 – 18:00</p>

                                    <div className={styles.contactsContainer2}>
                                        <img src={iconLocation} className={styles.contactsContainerIcon2} />
                                        <div onClick={() => window.open('https://goo.gl/maps/DkeMchgid41FNhGA7')} style={{ cursor: "pointer" }}>
                                            <p className={styles.contactsValue2}>Via Pietro Nenni 8 20032 Cormano (MI)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

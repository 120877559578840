import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import { library } from '@fortawesome/fontawesome-svg-core';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import * as regularIcons from '@fortawesome/free-regular-svg-icons';
import * as brandIcons from '@fortawesome/free-brands-svg-icons';

import Home from './routes/home/home';
import About from './routes/about/about';
import Services from './routes/services/services';
import Assistance from './routes/assistance/assitance';
import Brands from './routes/brands/brands';
import Contacts from './routes/contacts/contacts';
import Vehicle from './routes/vehicle/vehicle';
import Porsche from './routes/porsche/porsche';
import Faq from './routes/faq/faq';
import Listing from './routes/listing/listing';
import Bookmark from './routes/bookmark/bookmark';

import Terms from './routes/terms/terms';
import Cookie from './routes/terms/cookie';
import Privacy from './routes/terms/privacy';


import { BASENAME } from "./constant";

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';

const solidIconList = Object
    .keys(solidIcons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => solidIcons[icon])

const regularIconList = Object
    .keys(regularIcons)
    .filter(key => key !== "far" && key !== "prefix")
    .map(icon => regularIcons[icon])

const brandIconList = Object
    .keys(brandIcons)
    .filter(key => key !== "fab" && key !== "prefix")
    .map(icon => brandIcons[icon])

library.add(...brandIconList)
library.add(...solidIconList)
library.add(...regularIconList)


const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/chi-siamo",
        element: <About />,
    },
    {
        path: "/servizi",
        element: <Services />,
    },
    {
        path: "/assistenza",
        element: <Assistance />,
    },
    {
        path: "/marchi",
        element: <Brands />,
    },
    {
        path: "/contatti",
        element: <Contacts />,
    },
    {
        path: "/faq",
        element: <Faq />,
    },
    {
        path: "/privacy-policy",
        element: <Privacy />,
    },
    {
        path: "/cookie-policy",
        element: <Cookie />,
    },
    {
        path: "/termini-e-condizioni",
        element: <Terms />,
    },
    {
        path: "/porsche",
        element: <Porsche />,
    },
    {
        path: "/preferiti",
        element: <Bookmark />,
    },
    {
        path: "/veicoli",
        element: <Listing />,
    },
    {
        path: "/veicolo/:id",
        element: <Vehicle />,
    },
], { basename: BASENAME });



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <RouterProvider router={router} />
  </React.StrictMode>
);


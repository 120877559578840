import React, { useState , useEffect} from 'react';
import styles from "./style.module.scss";
import headerLink from "../header/headerLink";
import Button from '../button/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BASENAME } from "../../constant"
import { SMTP } from "../../constant"
import { isMobile } from 'react-device-detect';
import footerLogo from "../../img/mini_logo.png"

export default function Footer(props) {
    // per il controllo della larghezza dello schermo
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Call when page is rendered, it set windows size fore the used device
    useEffect(() => {
        // Function to update windowWidth when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup: remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function checkIpad(screenWidth) {
        if (screenWidth > 567)
            return true
        return false
    }

    return (
        <div className={styles.footerContainer} style={isMobile ? { marginTop:-56 } : { marginTop:-15 }}>
            <div className={"container py-5"}>
                <div className={"row"}>
                    <div className={"col-12 col-lg-1 text-center text-lg-start d-flex align-items-center justify-content-center"} style={isMobile ? {marginTop: -8, marginBottom: 20} : {marginBottom: 30}}>
                        <img src={footerLogo} alt="logo" className={styles.logo} />
                    </div>
                    <div className={"col-12 col-lg-4 d-flex flex-column text-center text-lg-start pt-5 pt-sm-0"}>
                        <div className={styles.infoTextBig}>
                            {/* <FontAwesomeIcon icon="phone" /> */}
                            <img src="../site_images/svg/Phone.svg" width={22} height={22} fill="white" style={{ textAlign: 'right', marginRight: 10 }} />
                            <a className={"unstyleLink"} href="tel:+39022540348" target="_blank">{headerLink.phone}</a>
                        </div>
                        <div className={styles.infoTextBig}>
                            <FontAwesomeIcon icon={["far", "envelope"]} />
                            <span onClick={() => window.open('mailto:'+SMTP)} style={{cursor: "pointer"}}>
                                {headerLink.mail}
                            </span>
                        </div>
                        { isMobile ? (checkIpad(windowWidth) ? 
                            (
                                <div className={styles.infoTextBig}>
                                    <img src={"../site_images/svg/Map_marker.svg"} width={24} height={24} fill="white" style={{marginLeft: -3}} />
                                    <a className={"unstyleLink"} href="https://goo.gl/maps/DLiuYGD75hQ53ym89" target="_blank">
                                        <span className={styles.infoTextAddress}> &ensp; Via per Imbersago 19 - Cologno Monzese (MI) 20093</span>
                                    </a>
                                </div>
                            ) : (
                                <>
                                    <div className='mb-3'></div>
                                    <div className='row align-items-center justify-content-center text-center text-md-start'>
                                        <div className='col-auto d-md-none'>
                                            <img src="../site_images/svg/Map_marker.svg" width={24} height={24} fill="white" style={{ textAlign: 'right', marginRight: -120 }} />
                                        </div>

                                        <div className='col'>
                                            <a className={"unstyleLink"} href="https://goo.gl/maps/DLiuYGD75hQ53ym89" target="_blank">
                                                <span style={{ fontSize: 15, display: 'block' }}>Via per Imbersago 19</span>
                                                <span style={{ fontSize: 15, display: 'block' }}>Cologno Monzese (MI) 20093</span>
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )) : (
                                <div className={styles.infoTextBig}>
                                    <img src={"../site_images/svg/Map_marker.svg"} width={24} height={24} fill="white" style={{ marginLeft: -3 }} />
                                    <a className={"unstyleLink"} href="https://goo.gl/maps/DLiuYGD75hQ53ym89" target="_blank">
                                        <span className={styles.infoTextAddress}> &ensp; Via per Imbersago 19 - Cologno Monzese (MI) 20093</span>
                                    </a>
                                </div>
                            )
                        }
                        { isMobile ? (checkIpad(windowWidth) ? 
                            (
                                <div className={styles.infoTextBig}>
                                    <img src={"../site_images/svg/Map_marker.svg"} width={24} height={24} fill="white" style={{marginLeft: -3}} />
                                    <a className={"unstyleLink"} href="https://goo.gl/maps/DkeMchgid41FNhGA7" target="_blank">
                                        <span className={styles.infoTextAddress}> &ensp; Via Pietro Nenni 8 - Cormano (MI) 20032</span>
                                    </a>

                                </div>
                            ) : (
                                <>
                                    <div className='mb-3'></div>
                                    <div className='row align-items-center justify-content-center text-center text-md-start'>
                                        <div className='col-auto d-md-none'>
                                            <img src="../site_images/svg/Map_marker.svg" width={24} height={24} fill="white" style={{ textAlign: 'right', marginRight: -190 }} />
                                        </div>

                                        <div className='col'>
                                            <a className={"unstyleLink"} href="https://goo.gl/maps/DkeMchgid41FNhGA7" target="_blank">
                                                <span style={{ fontSize: 15, display: 'block' }}>Via Pietro Nenni 8</span>
                                                <span style={{ fontSize: 15, display: 'block' }}>Cormano (MI) 20032</span>
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )) : (
                                <div className={styles.infoTextBig}>
                                    <img src={"../site_images/svg/Map_marker.svg"} width={24} height={24} fill="white" style={{marginLeft: -3}} />
                                    <a className={"unstyleLink"} href="https://goo.gl/maps/DkeMchgid41FNhGA7" target="_blank">
                                        <span className={styles.infoTextAddress}> &ensp; Via Pietro Nenni 8 - Cormano (MI) 20032</span>
                                    </a>
                                </div>
                            )
                        }
                        <div className='mb-3'></div>
                        
                    </div>

                    <div className="col-1"/>

                    <div className={"col-12 col-lg-2 flex-column d-none d-lg-flex"}>
                        {headerLink.bottom.map((link, index) => {
                            return (
                                <a key={"bottom-link-footer" + index} href={BASENAME +link.link} className={styles.footerLink}>{link.label}</a>
                            )
                        })}
                    </div>

                    <div className={"col-12 col-lg-2 flex-column d-none d-lg-flex"}>
                        {headerLink.top.map((link, index) => {
                            return (
                                <a key={"top-link-footer" + index} href={BASENAME +link.link} className={styles.footerLink}>{link.label}</a>
                            )
                        })}
                    </div>
                    <div className={"col-12 col-lg-2 flex-column d-none d-lg-flex"}>
                        {headerLink.privacy.map((link, index) => {
                            return (
                                <a key={"top-link-privacy" + index} href={BASENAME +link.link} className={styles.footerLink}>{link.label}</a>
                            )
                        })}
                    </div>
                </div>

                <div className={styles.socialContainerMobile}>
                    {headerLink.socials.map((social, index) => {
                        return (
                            <Button key={"social-footer-mobile-" + index} icon={social.icon} onClick={() => window.open(social.link, '_blank')} variation={"secondary"} />
                        )
                    })}
                </div>

                <div className={styles.divider} />

                <div className={"col-12"}>

                    <div className={styles.bottomContainer} style={isMobile ? {marginBottom:-12} : null}>
                        <div className={styles.copyrightContainer}>
                            {headerLink.copyright}
                        </div>
                        <div className={styles.socialContainer}>
                            {headerLink.socials.map((social, index) => {
                                return (
                                    <Button key={"social-footer-" + index} icon={social.icon} onClick={() => window.open(social.link, '_blank')} variation={"secondary"} style={{border:"2px solid #2F527A"}} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

